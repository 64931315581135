/* eslint-disable */
import React from "react";

export default {
  en: {
    quote: "Jawg has always been super responsive in providing support",
    author: "Mehdi Kaddache, CTO",
    sector: "Gift boxes and cards, activity booking",
    interviewees: ["Mehdi Kaddache, CTO"],
    service: "Dynamic Maps and Geocoding",
    geo: "World",
    blocks: [
      {
        title: "Wonderbox",
        description: (
          <>
            Present in more than 13 countries through its 20 brands,{" "}
            <a
              href="https://www.wonderbox.fr/"
              style={{ textDecoration: "underline" }}
              rel="noopener noreferrer"
              target="_blank"
            >
              Wonderbox
            </a>{" "}
            is a platform that helps its customers to create or offer activities
            and experiences. Wonderbox offers its experiences both as a physical
            box set available for purchase in stores and online through its
            website.
          </>
        ),
      },
      {
        description: (
          <>
            In their team, there is Mehdi Kaddache, Chief Technology Officer
            (CTO), responsible for the support, run and project teams at
            Wonderbox. Its teams take care of the entire basis of Wonderbox's
            IT, from development to security, including scaling during periods
            of high activity, such as during the holidays.
          </>
        ),
        img: "clientLogo",
      },
      {
        title: "Wondebox & Jawg",
        description: (
          <>
            In 2018, Google Maps is increasing its prices. That's when Wonderbox
            moved to Jawg Maps to find a more cost-effective solution.
            Initially, this raised quality questions due to Jawg being a smaller
            company. But these concerns quickly dissipated and today,{" "}
            <q>all Wonderbox specifications are met with Jawg</q>.
          </>
        ),
      },
      {
        description: (
          <>
            With the arrival of a new CTO (Mehdi Kaddache) and a few years
            later, the question of changing the map provider came. However, the
            team was extremely happy and{" "}
            <q>asked to favor Jawg if we were to do a competitive overview</q>.
          </>
        ),
      },
      {
        description: (
          <>
            As part of its services, integrating Jawg Maps{" "}
            <q>
              allows Wonderbox to bring a layer of speed to address search and
              modernity on maps
            </q>{" "}
            in order to provide an optimized customer experience.
          </>
        ),
        img: "usageHighlight",
      },
      {
        description: (
          <>
            As Wonderbox has grown and acquired new brands, their traffic and
            their consumption of Jawg Maps services has increased. The impact on
            their budget was controlled thanks to Jawg, who had{" "}
            <q>
              the audacity and honesty to make proposals to allow us to reduce
              our costs, it was very appreciable
            </q>
            .
          </>
        ),
      },
      {
        description: (
          <>
            <q>
              There is a really pleasant relationship with Jawg, we met people
              who have a sense of service, who are there to help their customers
              and that is very important, plus Jawg’s pricing is very
              competitive
            </q>
            , adds Mehdi.{" "}
          </>
        ),
      },
      {
        title: "Jawg Maps in 3 words?",
        description: "Service, Quality, Seriousness",
      },
    ],
  },
  fr: {
    quote: "Jawg a toujours été super réactif dans l’accompagnement",
    author: "Mehdi Kaddache, CTO",
    sector: "Coffrets et cartes cadeaux, réservation d’activité",
    interviewees: ["Mehdi Kaddache, CTO"],
    service: "Dynamic Maps et Geocoding",
    geo: "Monde",
    blocks: [
      {
        title: "Wonderbox",
        description: (
          <>
            Présent dans plus de 13 pays au travers de ses 20 marques,{" "}
            <a
              href="https://www.wonderbox.fr/"
              style={{ textDecoration: "underline" }}
              rel="noopener noreferrer"
              target="_blank"
            >
              Wonderbox
            </a>{" "}
            est une plateforme permettant de réaliser ou d’offrir des activités
            et expériences à ses clients. Disponible à la fois en tant que
            coffret physique à acheter en magasin, Wonderbox diffuse également
            ses expériences sur son site internet.
          </>
        ),
      },
      {
        description: (
          <>
            Dans leur équipe, on retrouve Mehdi Kaddache, Chief Technology
            Officer (CTO), responsable des équipes support, run et projet chez
            Wonderbox. Ses équipes sont chargées de l'intégralité du socle du SI
            de Wonderbox, du développement à sa sécurisation en passant par la
            mise à l'échelle en période d’activité élevée, comme lors des fêtes.
          </>
        ),
        img: "clientLogo",
      },
      {
        title: "Wondebox & Jawg",
        description: (
          <>
            En 2018, Google Maps augmente ses prix. C’est à ce moment-là que
            Wonderbox se tourne vers Jawg Maps afin de trouver une solution plus
            économique. Initialement, cela a soulevé des questions de qualité du
            fait que Jawg soit une plus petite entreprise. Mais ces inquiétudes
            se sont rapidement dissipées et aujourd'hui,{" "}
            <q>
              &#160;tout le cahier des charges de Wonderbox est respecté avec
              Jawg&#160;
            </q>
            .
          </>
        ),
      },
      {
        description: (
          <>
            Avec l’arrivée d’un nouveau CTO (Mehdi Kaddache) et quelques années
            plus tard, la question de changer de fournisseur s'est posée.
            Cependant, l'équipe était extrêmement satisfaite et{" "}
            <q>
              &#160;ils demandaient de favoriser Jawg si on devait faire un tour
              d’horizon de la concurrence&#160;
            </q>
            .
          </>
        ),
      },
      {
        description: (
          <>
            Dans le cadre de ses services, le fait d’intégrer Jawg Maps{" "}
            <q>
              &#160;permet à Wonderbox d’apporter une couche de rapidité au
              niveau de la recherche d’adresse et de modernisme sur tout ce qui
              est maps&#160;
            </q>{" "}
            afin de fournir une expérience client optimisée.
          </>
        ),
        img: "usageHighlight",
      },
      {
        description: (
          <>
            Au fil de sa croissance et l'acquisition de nouvelles marques,
            Wonderbox a constaté une augmentation du trafic et de la
            consommation des services Jawg. L'impact sur leur budget a pu être
            maîtrisé grâce à Jawg, qui a eu{" "}
            <q>
              &#160;l’audace et l'honnêteté de faire des propositions pour nous
              permettre de réduire nos coûts, ça a été très appréciable&#160;
            </q>
            .
          </>
        ),
      },
      {
        description: (
          <>
            <q>
              &#160;Il y a une relation qui est vraiment agréable avec Jawg, on
              sent des gens qui ont le sens du service, qui sont là pour aider
              leurs clients et ça c’est très important, de plus niveau tarifaire
              Jawg est très compétitif&#160;
            </q>
            , ajoute Mehdi.
          </>
        ),
      },
      {
        title: "Jawg Maps en 3 mots ?",
        description: "Service, Qualité, Sérieux",
      },
    ],
  },
};
